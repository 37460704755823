<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="customerName"
      :section="'All Time Orders'"
    />
    <div>
      <eden-table-actions :title="title" :show-search="false">
        <template slot="actions">
          <el-button
            v-if="allowDelete"
            type="plain"
            icon="eden-icon-delete"
            class="mr-1"
            @click="showOrdersDelete = true"
          >
            Delete orders
          </el-button>
          <eden-periods
            :default-period="'thisyear'"
            :custom-periods="periods"
            @set-period="getCustomerOrders"
          />
        </template>
      </eden-table-actions>
      <template>
        <p v-if="loading">Loading</p>
        <template v-else>
          <el-table
            :data="data"
            empty-text="No Orders"
            @select-all="setSelectedOrders"
            @selection-change="setSelectedOrders"
          >
            <el-table-column
              v-if="allowAccessFor(['superadmin', 'gardener_pro'])"
              type="selection"
              width="55"
              :selectable="allowRowSelect"
            ></el-table-column>
            <el-table-column width="120">
              <template slot="header">
                <div class="table--header">
                  <span>Order Id</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <eden-indicator
                  class="mr-4"
                  :status="scope.row.paused ? 'paused' : 'active'"
                />
                <router-link
                  :to="{
                    name: 'orders.order',
                    params: { id: scope.row.order_id },
                  }"
                  ><span class="text-primary">{{ scope.row.order_id }}</span>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <div class="table--header">
                  <span>Cycle</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  <el-tag v-if="scope.row.is_current_cycle" type="info"
                    >Current Cycle</el-tag
                  >
                  <span v-else>-</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <div class="table--header">
                  <span>Service</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm capitalize">
                  <el-tag :type="setServiceType(scope.row.service)">{{
                    formatText(scope.row.service)
                  }}</el-tag>
                </span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Customer Pickup</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span v-if="scope.row.service.toLowerCase() === 'laundry'">
                  {{
                    formatDate(scope.row.pickup_from_customer, "dddd do, m, y")
                  }}
                </span>
                <span v-else>Delivery only</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Customer Delivery</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{
                  formatDate(scope.row.deliver_to_customer, "dddd do, m, y")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <div class="table--header">
                  <span>Completed Status</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatText(scope.row.completed_status) }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination
            :from="from"
            :to="to"
            :total="total"
            :current-page.sync="page"
          />
        </template>
      </template>
    </div>
    <customer-specific-orders-delete
      :show.sync="showOrdersDelete"
      :customer-id="customerId"
      :orders="selectedOrders"
      @success="getCustomerOrders"
    />
  </div>
</template>

<script>
import CustomerSpecificOrdersDelete from "@/components/Customers/Individual/Actions/Orders/CustomerSpecificOrdersDelete";

import * as actions from "@/store/action-types";

export default {
  name: "CustomerOrders",
  components: { CustomerSpecificOrdersDelete },
  data() {
    return {
      period: "thisyear",
      periods: {
        lastyear: "Last year",
        lastmonth: "Last month",
        thismonth: "This month",
        thisyear: "This year",
        nextyear: "Next year",
      },
      loading: false,
      page: 1,
      unwatchPage: false,
      showOrdersDelete: false,
      selectedOrders: [],
    };
  },
  computed: {
    orders() {
      return this.$store.getters.customer_orders;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    data() {
      return this.orders.pages[this.page].data;
    },
    title() {
      return `${this.total || 0} Orders`;
    },
    customerId() {
      return this.$route.params.id;
    },
    customerName() {
      return `${
        this.loading ? "-" : this.formatName(this.data[0]?.customer_name)
      }`;
    },
    allowDelete() {
      return (
        this.selectedOrders.length &&
        this.allowAccessFor(["superadmin", "gardener_pro"])
      );
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.orders.pages[this.page];
      if (!pageFetched) {
        this.getCustomerOrders({ period: this.period });
      }
    },
  },
  created() {
    this.getCustomerOrders({ period: "thisyear" });
  },
  methods: {
    allowRowSelect(row) {
      if (this.allowAccessFor(["superadmin"])) {
        return true;
      }
      return !row.is_current_cycle;
    },
    setSelectedOrders(orders) {
      this.selectedOrders = orders.map((order) => order.order_id);
    },
    getCustomerOrders({ period }) {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_CUSTOMER_ORDERS, {
          id: this.customerId,
          period,
          page: this.page,
        })
        .then(() => {
          this.period = period;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
